
import CommonMixin from '@/mixins/CommonMixin';
import FormMixin from '@/components/Management/Mixins/FormMixin';
import { handleErrorUI } from '@/util/error';
import { formatCurrencyWithSymbol } from '@/models/formatters/CurrencyFormatter';
import { generateImageDataUrl } from '@/util/file';

export default {
  mixins: [CommonMixin, FormMixin],
  data() {
    return {
      loading: true,
      product: {}
    };
  },
  computed: {
    productId() {
      return this.$route.params.id as string;
    },
    productPrice() {
      const { price, currency } = this.product;
      if (!price || !currency) return '';
      return formatCurrencyWithSymbol(price, currency.code);
    }
  },
  mounted() {
    this.loadProduct(this.productId);
  },
  methods: {
    async loadProduct(id: string) {
      this.loading = true;
      try {
        this.product = await this.$repo.product.getProduct(id);
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      }
    },
    async fetcher(path: string) {
      return await this.$repo.product.downloadProductImage(path);
    },
    formatter(data: string, url: string) {
      return generateImageDataUrl(data, url);
    }
  }
};
